body {
  font-family: Roboto, sans-serif;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.ant-layout {
  min-height: 100vh;
}
.ant-layout-sider {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
  background-color: #013476 !important;
}
.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}
.ant-menu {
  background-color: #013476 !important;
}
.logo {
  background-color: #d3d3d3;
  margin: 0;
  height: 55px;
}
main {
  height: 85vh;
  overflow-y: scroll;
  background-color: transparent !important;
}
main::-webkit-scrollbar {
  width: 0px;
}
.ant-layout-header h5 {
  line-height: 20px;
  font-size: 14px;
}
.ant-layout-header p {
  line-height: 20px;
  font-size: 14px;
}
.ant-layout-header div .badge {
  top: 16px;
  right: -6px;
}
.error {
  color: rgb(196, 2, 2);
  font-size: 12px;
}
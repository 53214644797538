:root {
  --color-007600: #007600;
  --color-013476: #013476;
  --color-d3d3d3: #d3d3d3;
  --color-1677ff: #1677ff;
  --color-ffffff: #ffffff;
  --color-ff0000: #ff0000;
  --color-ffaa00: #ffaa00;
  --color-212529: #212529;
  --color-dddddd: #dddddd;
  --color-828599: #828599;
}
.green {
  font-size: 14px;
  font-family: Roboto !important;
  color: var(--color-007600);
}
.red {
  font-size: 14px;
  font-family: Roboto !important;
  color: var(--color-ff0000);
}
.yellow {
  font-size: 14px;
  font-family: Roboto !important;
  color: var(--color-ffaa00);
}
.quill {
  background-color: var(--color-ffffff);
}
.form-control {
  box-shadow: none;
  border-color: var(--color-dddddd);
}
.heading {
  font-size: 28px;
  font-family: Roboto !important;
  color: var(--color-212529);
}
.sub-heading {
  font-size: 24px;
  font-family: Roboto !important;
  color: var(--color-212529);
}
.desc {
  font-size: 14px;
  font-family: Roboto !important;
  color: var(--color-828599);
}